<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="优惠券标题" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入优惠券标题"></a-input>
					</a-form-item>

					<a-form-item label="券类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" placeholder="请选择券类型" allow-clear style="width: 180px;">
							<a-select-option :value="1">代金券</a-select-option>
							<a-select-option :value="2">兑换券</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="用户手机号" name="phone" class="ui-form__item">
						<a-input v-model:value="formState.phone" placeholder="请输入用户手机号"></a-input>
					</a-form-item>

					<!-- <a-form-item label="券号" name="code" class="ui-form__item">
						<a-input v-model:value="formState.code" placeholder="请输入券号"></a-input>
					</a-form-item> -->

					<a-form-item label="券用途" name="useLimit" class="ui-form__item">
						<a-select v-model:value="formState.useLimit" placeholder="请选择券用途" allow-clear style="width: 180px;">
							<a-select-option :value="1">影片</a-select-option>
							<a-select-option :value="2">卖品</a-select-option>
							<a-select-option :value="3">商城</a-select-option>
							<a-select-option :value="4">演出</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="券状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" placeholder="请选择券状态" allow-clear style="width: 180px;">
							<a-select-option :value="1">待使用</a-select-option>
							<a-select-option :value="2">已使用</a-select-option>
							<a-select-option :value="3">已过期</a-select-option>
							<a-select-option :value="4">未激活</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="发放类型" name="fromType" class="ui-form__item">
						<a-select v-model:value="formState.fromType" placeholder="请选择发放类型" allow-clear style="width: 180px;">
							<a-select-option :value="1">用户领取</a-select-option>
							<a-select-option :value="2">购买卡包</a-select-option>
							<a-select-option :value="3">转赠</a-select-option>
							<a-select-option :value="4">后台发放</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="24" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
					:scroll="{ x: 2500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'type'">
							<div v-if="record.type === 1">代金券</div>
							<div v-if="record.type === 2">兑换券</div>
						</template>
						<template v-if="column.key === 'useLimit'">
							<div v-if="record.useLimit === 1">影片</div>
							<div v-if="record.useLimit === 2">卖品</div>
							<div v-if="record.useLimit === 3">商城</div>
							<div v-if="record.useLimit === 4">演出</div>
						</template>
						<template v-if="column.key === 'info'">
							<div>用户昵称：{{ record.nickname || '-' }}</div>
							<div>用户手机号：{{ record.phone || '-' }}</div>
						</template>
						<template v-if="column.key === 'isLimitPrice'">
							<div v-if="record.type == 1">{{ record.limitPrice }}元</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'deductionPrice'">
							<div v-if="record.type == 1">{{ record.deductionPrice }}元</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'diffPrice'">
							<div v-if="record.type == 2">{{ record.diffPrice }}元</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'fromType'">
							{{ ['用户领取', '购买卡包', '转赠', '后台发放'][record.fromType - 1] }}
						</template>
						<template v-if="column.key === 'status'">
							{{ ['待使用', '已使用', '已过期', '未激活'][record.status - 1] }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ ['已启用', '已禁用'][record.isDisabled] }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'useTime'">
							<div v-if="record.useTime">{{ transDateTime(record.useTime) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'time'">
							<div>{{ transDateTime(record.startTime) }}</div>
							至
							<div>{{ transDateTime(record.endTime) }}</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['coupon_snack_info_disabled']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
											<a-menu-item>启用</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_info_disabled']" v-else
											@click="onDisabled(record)">
											<a-menu-item>禁用</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

	</div>
</template>

<script>
	import {
	  Icon
	} from '@/components/icon/icon.js';
	import {
		getUserCouponList,
		disabledUserCoupon
	} from "@/service/modules/coupon";

	export default {
		components: { Icon },
		data() {
			return {
				searchData: [],
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				formState: {},
				columns: [{
					title: '券标题',
					dataIndex: 'title',
				}, {
					title: '券类型',
					key: 'type',
					width: 120
				}, {
					title: '券用途',
					key: 'useLimit',
					width: 120
				}, 
				// {
				// 	title: '券号',
				// 	dataIndex: 'code',
				// }, 
				{
					title: '用户信息',
					key: 'info'
				}, {
					title: '满额可使用',
					key: 'isLimitPrice',
					width: 120
				}, {
					title: '抵扣金额',
					key: 'deductionPrice',
					width: 120
				}, {
					title: '补差金额',
					key: 'diffPrice',
					width: 120
				}, {
					title: '券状态',
					key: 'status',
					width: 100
				}, {
					title: '使用日期',
					key: 'useTime'
				}, {
					title: '获得日期',
					key: 'createTime'
				}, {
					title: '发放类型',
					key: 'fromType',
					width: 120
				},  {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '操作',
					fixed: 'right',
					width: 100,
					key: 'action'
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getUserCouponList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onDisabled(item) {
			  this.$confirm({
			    title: '提示',
			    content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该兑换券吗？',
			    onOk: async () => {
			      this.loading = true;
			      try {
			        let ret = await disabledUserCoupon({
			          id: item.id,
			          isDisabled: item.isDisabled ? 0 : 1
			        })
			        this.loading = false;
			        if (ret.code === 200) {
			          this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
			          this.getData();
			        }
			      } catch(e) {
			        console.log(e)
			        this.loading = false;
			      }
			    }
			  })
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>